/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// import { wrapRootElement as wrap } from './root-wrapper'

// export const wrapRootElement = wrap

import './src/styles/tailwind.css'
import './src/styles/main.scss'

import React from 'react'

export const wrapRootElement = ({ element }) => {
  return <>{element}</>
}

export const onClientEntry = () => {
  if (
    process.env.WORK_IN_PROGRESS === 'true' &&
    !window.location.pathname.includes('/wip')
  ) {
    window.location = '/wip'
  }
}

export const onRouteUpdate = () => {
  console.log('onRouteUpdate :: ', window.location.pathname)
  if (
    process.env.WORK_IN_PROGRESS === 'true' &&
    !window.location.pathname.includes('/wip')
  ) {
    window.location = '/wip'
  }
}
